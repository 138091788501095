import React from "react";
import {
  LayoutContent,
  LayoutContainer,
  LayoutColumns,
  LayoutColumn,
} from "@paljs/ui/Layout";
import Header from "../components/Header";

const LayoutBasic = ({ children }) => (
  <React.Fragment>
    <Header />
    <LayoutContainer>
      <LayoutContent>
        <LayoutColumns>
          <LayoutColumn className="main-content">{children}</LayoutColumn>
        </LayoutColumns>
      </LayoutContent>
    </LayoutContainer>
  </React.Fragment>
);

export default LayoutBasic;
