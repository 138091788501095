import React from "react";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import { LoadingCard } from "../components/Loading/LoadingCard";
import Fetch from "../components/Fetch";
import { OrderRequest } from "../components/Cards/OrderRequest";

const Tracking = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [orders, setOrders] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    Fetch.get("/tracking")
      .then((response) => {
        if (response.data && response.data.length) setOrders(response.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <div className="col-flex-center">
        <LoadingCard isLoading={isLoading}>
          <Col breakPoint={{ md: 12 }}>
            <Row>
              {!!orders &&
                !!orders.length &&
                orders.map((order, i) => (
                  <Col breakPoint={{ md: 4 }} key={i}>
                    <OrderRequest order={order} />
                  </Col>
                ))}
            </Row>
          </Col>
        </LoadingCard>
      </div>
    </>
  );
};
export default Tracking;
