import React from 'react';
import Select from '@paljs/ui/Select';
import { useFetch } from '../Fetch';


export default function SelectTypeProblem({ onChange, value }) {
  const { isLoading, error, data } = useFetch('/publicdata/typeproblem', { method: 'get' });

  const optionsProblems =
    data && data.length
      ? data.map((x) => ({ value: x.id, label: x.description }))
      : [];

  return (
    <React.Fragment>
      <Select options={optionsProblems} placeholder="Tipo de problema" isLoading={isLoading} onChange={onChange} value={value} />
    </React.Fragment>
  );
}
