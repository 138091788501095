import React from 'react';
import Select from '@paljs/ui/Select';
import { useFetch } from '../Fetch';


export default function SelectBuilding({ onChange, value }) {
  const { isLoading, error, data } = useFetch('/publicdata/building', { method: 'get' });

  const optionsBuildings =
    data && data.length
      ? data.map((x) => ({ value: x.id, label: `${x.name} / ${x.city} - ${x.uf}`, urlImage: x.urlImage }))
      : [];

  return (
    <React.Fragment>
      <Select options={optionsBuildings} placeholder="Empreendimento" isLoading={isLoading} onChange={onChange} value={value} />
    </React.Fragment>
  );
}
