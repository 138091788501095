import React from "react";
import LayoutBasic from "./layouts/LayoutBasic";
import Home from "./pages/Home";
import Terms from "./pages/Terms";
import RequestAttendance from "./pages/RequestAttendance";
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Tracking from './pages/Tracking';
import AttendanceInfo from "./pages/AttendanceInfo";
import Policy from "./pages/Policy";

export default [
  {
    path: "/",
    exact: true,
    layout: LayoutBasic,
    component: Home,
  },
  {
    path: "/newsupport",
    layout: LayoutBasic,
    component: RequestAttendance,
  },
  {
    path: "/terms",
    layout: ({ children }) => <React.Fragment>{children}</React.Fragment>,
    component: Terms,
  },
  {
    path: "/policy",
    layout: ({ children }) => <React.Fragment>{children}</React.Fragment>,
    component: Policy,
  },
  {
    path: "/newsupport",
    layout: LayoutBasic,
    component: RequestAttendance,
  },
  {
    path: "/login",
    layout: LayoutBasic,
    component: Login,
  },
  {
    path: "/tracking",
    layout: LayoutBasic,
    component: Tracking,
  },
  {
    path: "/info",
    layout: LayoutBasic,
    component: AttendanceInfo,
  },
  {
    layout: LayoutBasic,
    component: NotFound,
  },
];
